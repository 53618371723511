import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies, { CookieAttributes } from 'js-cookie';
import { RootState } from '../../model/slices/root/root.state';
import { ONBOARDING_UNLOCKED_COOKIE_NAME } from '../../model/constants';
import { actions } from '../../model/slices/app/app.slice';
import getCookieAttributes from '../../utils/get-cookie-attributes/get-cookie-attributes';

// TODO: Remove this once the pharmacy is up to speed
const useOnboardingUnlocked = (): [boolean, (unlocked: boolean) => void] => {
  const dispatch = useDispatch();
  const isOnboardingUnlockedState = useSelector(
    (state: RootState) => state.app.isOnboardingUnlocked
  );

  React.useEffect(() => {
    if (isOnboardingUnlockedState == null) {
      const isOnboardingUnlockedCookieValue = Cookies.get(ONBOARDING_UNLOCKED_COOKIE_NAME);
      dispatch(actions.setIsOnboardingUnlocked(isOnboardingUnlockedCookieValue === '1'));
    }
  }, [dispatch, isOnboardingUnlockedState]);

  const setIsOnboardingUnlocked = React.useCallback(
    (isOnboardingUnlocked: boolean) => {
      const cookieAttributes: CookieAttributes = getCookieAttributes(365);
      const isOnboardingUnlockedCookieValue = isOnboardingUnlocked ? '1' : '0';
      Cookies.set(
        ONBOARDING_UNLOCKED_COOKIE_NAME,
        isOnboardingUnlockedCookieValue,
        cookieAttributes
      );
      dispatch(actions.setIsOnboardingUnlocked(isOnboardingUnlocked));
    },
    [dispatch]
  );

  return [isOnboardingUnlockedState ?? false, setIsOnboardingUnlocked];
};

export default useOnboardingUnlocked;
