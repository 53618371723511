import { v4 as uuidv4 } from 'uuid';
import { Order } from '../../model/entities/commerce/order.entity';
import { PurchaseOptionType } from '../get-purchase-option/get-purchase-option';
import { User } from '../../model/entities/user.entity';
import { Gender } from '../../model/types/gender';
import { DiseaseCategory } from '../../model/entities/patient-case-report.entity';
import { SHORTHAND_CATEGORY_BY_DISEASE_CATEGORY } from '../../model/constants';
import { SubscriptionPreferences } from '../../model/entities/commerce/subscription.entity';

export enum PurchaseFlowType {
  LegacyToEcom = 'legacyToEcom',
  NewOnboarding = 'newOnboarding',
  Repurchase = 'repurchase',
  TreatmentAdjustment = 'treatmentAdjustment',
}

const normalizeValue = (value: string) => value.trim().toLowerCase();

const hashValueSha256 = async (value: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(bytes => bytes.toString(16).padStart(2, '0')).join('');
};

const createUserData = async (user: User) => {
  const { email, phoneNumber, gender, dateOfBirth, firstName, lastName } = user;
  const hashedEmail = email && (await hashValueSha256(normalizeValue(email.value)));
  const hashedPhone = phoneNumber && (await hashValueSha256(normalizeValue(phoneNumber.value)));
  const hashedFirstName = await hashValueSha256(normalizeValue(firstName));
  const hashedLastName = await hashValueSha256(normalizeValue(lastName));

  const fbGender = gender === Gender.Male ? 'm' : 'f';
  const hashedFbGender = await hashValueSha256(normalizeValue(fbGender));

  const fbDateOfBirth = dateOfBirth.replace(/-/g, '').slice(0, 8);
  const hashedFbDateOfBirth = await hashValueSha256(normalizeValue(fbDateOfBirth));

  return {
    user_id: user.id,
    sha256_email_address: hashedEmail,
    sha256_phone_number: hashedPhone,
    address: {
      sha256_first_name: hashedFirstName,
      sha256_last_name: hashedLastName,
    },
    x_fb_ud_ge: hashedFbGender,
    x_fb_ud_db: hashedFbDateOfBirth,
  };
};

export const gtmSetUserData = async (user: User | null) => {
  if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
    const userData = user ? await createUserData(user) : undefined;
    window.dataLayer.push({
      event: 'user_data_update',
      user_data: userData,
    });
  }
};

// Track GTM purchase
export const gtmTrackPurchase = (
  order: Order,
  purchaseOptionType: PurchaseOptionType | undefined,
  diseaseCategory: DiseaseCategory,
  purchaseFlowType: PurchaseFlowType,
  subscriptionPreferences: SubscriptionPreferences | undefined
) => {
  if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      purchase_option: purchaseOptionType,
      purchase_vertical: SHORTHAND_CATEGORY_BY_DISEASE_CATEGORY[diseaseCategory],
      purchase_flow_type: purchaseFlowType,
      purchase_subscription_preferences: subscriptionPreferences,
      x_fb_event_id: uuidv4(),
      ecommerce: {
        transaction_id: order.id.toString(),
        value: order.totalIncTax,
        tax: order.totalTax,
        currency: 'SEK',
        items: order.items.map(item => ({
          item_id: item.productId.toString(),
          item_name: item.name || `Product ${item.productId}`,
          price: item.price,
          item_variant: item.variantId.toString(),
          quantity: item.quantity,
        })),
      },
    });
  }
};

export const gtmTrackCustomPageView = () => {
  if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({ event: 'gtm_page_view' });
  }
};

export const gtmTrackCaseReportExistenceWarning = () => {
  if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({ event: 'case_report_existence_warning' });
  }
};

export const gtmTrackCaseReportGenderWarning = () => {
  if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({ event: 'case_report_gender_warning' });
  }
};
